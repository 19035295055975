@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

@font-face {
  font-family: HelveticaNeuePro67;
  src: url('fonts/helvetica-neue-lt-pro-67-mdcn.otf') format('opentype');
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}

div#root {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

body {
  background-color: #000;
  user-select: none;

  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  video,
  img {
    cursor: none;
  }
}
