.Splash {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 75vh;
    max-width: 50vw;
    max-height: 350px;
  }
}
