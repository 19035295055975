.PriceTable {
  position: absolute;

  li {
    list-style-type: none;
    color: #fff;
    font-family: 'HelveticaNeuePro67', sans-serif;
    border-radius: 4px;

    span {
      float: right;
    }
  }
}
