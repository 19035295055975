$padding: 16px;

.ErrorMessage {
  display: flex;
  flex-direction: row;
  width: 450px;
  background-color: #c23d40;
  border-radius: 8px;
  color: #fff;

  .error-icon {
    justify-content: center;

    img {
      height: 48px;
      padding: $padding;
    }
  }

  .error-text {
    padding: $padding + 4 $padding $padding 0;

    flex: 1;
    justify-content: center;

    h1 {
      padding: 0;
      padding-bottom: 8px;
      font-weight: 500;
      font-size: 18px;
    }

    p {
      padding: 0;
      font-size: 14px;
    }
  }
}
