$padding: 16px;

.InfoMessage {
  display: flex;
  flex-direction: row;
  width: 450px;
  background-color: #3d77c2;
  border-radius: 8px;
  color: #fff;

  .info-icon {
    justify-content: center;

    img {
      height: 48px;
      padding: $padding;
    }
  }

  .info-text {
    padding: $padding + 4 $padding $padding 0;

    flex: 1;
    justify-content: center;

    h1 {
      padding: 0;
      padding-bottom: 8px;
      font-weight: 500;
      font-size: 18px;
    }

    p {
      padding: 0;
      font-size: 14px;
    }

    button {
      cursor: pointer;
      border: none;
      outline: none;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 6px 18px;
      min-width: 120px;
      border-radius: 4px;
      color: #fff;

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
